import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
import bankenesLogo from "../../../static/img/logo/bankenes-logo.png";
import fellesLogo from "../../../static/img/logo/felles-logo.png";
import logosymbol from "../../../static/img/logo/logosymbol.png";
import farger1 from "../../../static/img/logo/farger1.png";
import farger2 from "../../../static/img/logo/farger2.png";
import kontrast from "../../../static/img/logo/kontrast.png";
import ikkeEndreLogoene from "../../../static/img/logo/ikke-endre-logoene.png";
import beskyttelsesomrade from "../../../static/img/logo/beskyttelsesomrade.png";
import plassering from "../../../static/img/logo/plassering.png";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Bankenes logo`}</h2>
    <p>{`Hver bank har sin egen lokale logo som består av hele banknavnet og symbolet.`}</p>
    <img src={bankenesLogo} alt="Illustrasjon av fire måter å bruke lokal banklogo" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Felles logo`}</h2>
    <p>{`SpareBank 1s felles logo brukes når det ikke er mulig å bruke lokal logo.`}</p>
    <img src={fellesLogo} alt="Illustrasjon av SpareBank 1 logo" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Logosymbol`}</h2>
    <p>{`Husk at vi som hovedregel bruker hele logoen vår. Unntaksvis må vi noen ganger la logosymbolet gjøre jobben alene for å få synlighet.`}</p>
    <p>{`Bruk symbolet i små digitale flater der logoen ikke gir god nok synlighet. For eksempel kan logosymbolet stå alene i sosiale medier.`}</p>
    <img src={logosymbol} alt="SpareBank 1 logosymbol" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Farger`}</h2>
    <p>{`SpareBank 1-logoene finnes i to ulike varianter`}</p>
    <ul>
      <li parentName="ul">{`Blå og rød (positiv), til bruk på hvit bakgrunn`}</li>
      <li parentName="ul">{`Hvit og rød (negativ), som skal brukes på blå bakgrunn`}</li>
    </ul>
    <p>{`De to logoene er likeverdige, pass derfor på at de brukes omtrent like ofte.`}</p>
    <p>{`Logosymbolet brukes alltid i primærfargene rødt og hvitt. Bruker du symbolet på hvit bakgrunn, bør det alltid stå i nærheten av noe blått.`}</p>
    <img src={farger1} alt="SpareBank 1 logo i to fargevarianter" className="sb1ds-layout__spacing-bottom--md" />
    <p>{`Når det ikke er mulig å bruke fargelogoen, kan den brukes i én farge på mørk eller lys bakgrunn`}</p>
    <ul>
      <li parentName="ul">{`Ensfarget svart (positiv)`}</li>
      <li parentName="ul">{`Ensfarget hvit (negativ)`}</li>
    </ul>
    <img src={farger2} alt="SpareBank 1 logo i sort-hvitt" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Kontrast`}</h2>
    <p>{`Husk at kontrastene mellom logo og bakgrunn alltid skal være tilstrekkelig og skal score godt på kravene fra WCAG. Målet er alltid å få logoen til å fremstå så tydelig som mulig.`}</p>
    <img src={kontrast} alt="Illustrasjon av logoen med god kontrast" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Ikke endre logoene`}</h2>
    <p>{`Det er ikke tillatt med andre fargekombinasjoner, å legge til skygger, eller å legge til andre navn sammen logoen. Hvis vi har behov for å forklare et tillegg skal det gjøres i løpende tekst og ikke i tilknytning til logo.`}</p>
    <img src={ikkeEndreLogoene} alt="Eksempler på hvordan logoen ikke skal brukes" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Beskyttelsesområde`}</h2>
    <p>{`Logoen skal som hovedregel ha en fast avstand til nærliggende elementer. Hvis hovedlogo brukes skal luften rundt logoen tilsvare en halv kule/sirkel for å beskytte logoen og lokalbanknavnet fra nærliggende elementer.`}</p>
    <img src={beskyttelsesomrade} alt="Illustrasjon av luft rundt logoen" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Plassering`}</h2>
    <p>{`Logoen skal alltid plasseres øverst eller nederst i et hjørne av formatet.`}</p>
    <img src={plassering} alt="Illustrasjon av hvordan logo kan plasseres" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Last ned logo`}</h2>
    <p>{`Du kan laste ned alle logoene fra Merkevare- og profilsenteret. Logoene ligger også tilgjengelig i Figma. `}</p>
    <p>{`Du finner Merkevare- og profilsenteret ved å gå inn på Innsikt og klikke på menypunktet «Merkevare og profilsenter» under «Hjelp».`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      